export default {
  proxy_path: "https://8jr0mp53c5.execute-api.us-east-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://10grerzv64.execute-api.us-east-2.amazonaws.com/api",
  google_api_key: "AIzaSyAVCuQtOaN6jrjHl89-Gi6FS2MKXHMOIwE",
  main_company_term_id: "6",
  crm_base_url: "https://crm.testing.vale.forwoodsafety.com",
  forwood_id_url: "https://id.testing.vale.forwoodsafety.com?redirect_uri=https://mapreport.testing.vale.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.testing.vale.forwoodsafety.com",
  Auth: {
    userPoolId: "us-east-2_HvAtul4tj",
    userPoolWebClientId: "7p0hceu1hnu07l0tep01kdm5uf",
    cookieStorage: {
      domain: ".testing.vale.forwoodsafety.com",
      secure: true
    }
  }
};
